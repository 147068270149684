/* eslint-disable react/display-name */
import React from 'react';
import { SCENARIOS, CONTEXT } from 'woop-shared/enums';
import { PROMPT_NAMES } from 'woop-shared/prompts/names';
import { REFERRER_CTA } from '../../constants/signup';

export function getHeader(scenario) {
  switch (scenario) {
    case SCENARIOS.TEST_DRIVE:
    case SCENARIOS.ESTIMATE:
      return `You're all set!`;
    case SCENARIOS.SIGN_UP:
      return 'Welcome aboard!';
  }
}

export function getText(scenario, referrerCTA) {
  switch (scenario) {
    case SCENARIOS.TEST_DRIVE:
    case SCENARIOS.ESTIMATE:
      return () => (
        <p>
          One of our insurance experts will get back to you shortly with an estimation of your
          insurance costs!
        </p>
      );
    case SCENARIOS.SIGN_UP:
      return getSignUpText(referrerCTA);
  }
}

function getSignUpText(referrerCTA) {
  if (referrerCTA === REFERRER_CTA.QUOTES) {
    return () => (
      <>
        <p>Your quotes are on the way! An agent will notify you when they are ready.</p>
        <p>In the meantime, check out your new insurance dashboard!</p>
      </>
    );
  }
  if (referrerCTA === REFERRER_CTA.REPORT) {
    return () => (
      <>
        <p>{"You're one step closer to controlling your insurance rates!"}</p>
        <p>
          {`It may take a few minutes for your report to be generated. We will notify you via email as soon as it's ready. In the meantime, check out your new insurance dashboard!`}
        </p>
      </>
    );
  }
  return () => (
    <>
      <p>{"You're one step closer to controlling your insurance rates!"}</p>
      <p>
        Explore your new dashboard to manage insurance policies, view price breakdowns, and get
        insights for smarter coverage decisions.
      </p>
    </>
  );
}

export function shouldShowDashboardLink(scenario) {
  return scenario === SCENARIOS.SIGN_UP;
}

export function getEncodedUrlParams(email, phone) {
  return `?${PROMPT_NAMES.EMAIL}=${btoa(email)}&${PROMPT_NAMES.PHONE}=${btoa(phone)}`;
}

export function getContext(scenario, referrerCTA) {
  if (scenario === SCENARIOS.IV) return CONTEXT.IV;
  if (scenario !== SCENARIOS.SIGN_UP) return null;
  if (referrerCTA === REFERRER_CTA.QUOTES) return CONTEXT.QUOTES;
  return CONTEXT.DASHBOARD;
}
