import { createAction } from 'redux-actions';

export const SET_CARRIER = 'SET_CARRIER';
export const setCarrier = createAction(SET_CARRIER);

export const SET_LOGIN_FIELD = 'SET_LOGIN_FIELD';
export const setLoginField = createAction(SET_LOGIN_FIELD);

export const CONNECT = 'CONNECT';
export const connect = createAction(CONNECT);

export const CONNECT_SUCCESS = 'CONNECT_SUCCESS';
export const connectSuccess = createAction(CONNECT_SUCCESS);

export const PULL_SUCCESS = 'PULL_SUCCESS';
export const pullSuccess = createAction(PULL_SUCCESS);

export const CLEAR_AUTH_STATUS = 'CLEAR_AUTH_STATUS';
export const clearAuthStatus = createAction(CLEAR_AUTH_STATUS);

export const SET_MFA_OPTION = 'SET_MFA_OPTION';
export const setMfaOption = createAction(SET_MFA_OPTION);

export const CLEAR_CANOPY = 'CLEAR_CANOPY';
export const clearCanopy = createAction(CLEAR_CANOPY);

export const POLL_FOR_PULL = 'POLL_FOR_PULL';
export const pollForPull = createAction(POLL_FOR_PULL);

export const CONNECT_COMPLETE = 'CONNECT_COMPLETE';
export const connectComplete = createAction(CONNECT_COMPLETE);

export const CONNECT_EPILOGUE = 'CONNECT_EPILOGUE';
export const connectEpilogue = createAction(CONNECT_EPILOGUE);

export const POP_CANOPY_PULL = 'POP_CANOPY_PULL';
export const popCanopyPull = createAction(POP_CANOPY_PULL);
