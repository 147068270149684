import axios from 'axios';

const ENDPOINTS = {
  PULLS: `${process.env.AUTH_URI}/pull`,
  USERS: `${process.env.AUTH_URI}/users`
};

/**
 * Create a user record for portal access
 *
 * @param {object} params A collection of fields to save on the user.
 * @param {string} params.firstName
 * @param {string} params.lastName
 * @param {string} params.dob
 * @param {string} params.email
 * @param {string} params.phone
 * @param {string} params.oid
 * @param {string} params.journeyId
 * @param {string} params.scenario
 * @returns {{ id: string, exists: boolean }} A user id
 */
export const postUser = async ({
  firstName,
  lastName,
  dob,
  email,
  phone,
  oid,
  journeyId,
  scenario
}) => {
  const uri = ENDPOINTS.USERS;
  const res = await axios
    .post(uri, { firstName, lastName, dob, email, phone, oid, journeyId, scenario })
    .catch(error => {
      if (error?.response?.status === 409) return error.response;
      throw error;
    });
  return { ...res.data, exists: res?.status === 409 };
};

/**
 * Create a pull record with no associated user.
 
 * @param {object} params
 * @param {string} params.pullId
 * @param {string} params.journeyId
 * @returns {Promise}
 */
export const postPull = async ({ pullId, journeyId }) => {
  return axios.post(ENDPOINTS.PULLS, { pullId, journeyId });
};

/**
 * Update a pull record with a user.
 
 * @param {object} params
 * @param {string} params.pullId
 * @param {string} params.uid
 * @returns {Promise}
 */
export const patchPull = async ({ pullId, uid }) => {
  const uri = `${ENDPOINTS.PULLS}/${pullId}`;
  return axios.patch(uri, { userId: uid });
};

/**
 * Check if a user record already exists with the given email or phone.
 *
 * @param {object} params
 * @param {string} params.phone
 * @param {string} params.email
 * @returns {Promise<boolean>}
 */
export const checkIfUserExists = async ({ phone, email }) => {
  const uri = `${ENDPOINTS.USERS}/exists`;
  const params = new URLSearchParams({ phone, email }).toString();
  const { data } = await axios.get(`${uri}?${params}`);
  return data.exists;
};
