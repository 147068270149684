import PropTypes from 'prop-types';
import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SCENARIOS } from 'woop-shared/enums';
import { CONNECT_ROUTE_PAGES } from '../../../../../../router/constants/routes';
import { connectRoute } from '../../../../../../router/utils';
import { PATCH_JOURNEY } from '../../../../../journey/actions/journey';
import { clearCanopy, connectEpilogue } from '../../../../actions/canopy';
import { setScenario } from '../../../../actions/scenario';
import { QUERY_PARAMS } from '../../../../constants/url';
import { useDealershipPartnerName } from '../../../../hooks/use-dealership-partner-name';
import { getQueryStringParam } from '../../../../utils/url';
import Button from '../../../Button';
import Checkbox from '../../../Checkbox';
import H3 from '../../../headers/H3';
import LinkText from '../../../LinkText';
import SuccessCheckmark from '../../../SuccessCheckmark';
import styles from './styles.css';
import { getButtonText, getText, showConnectAnother } from './utils';

const SuccessScreen = ({ onContinue }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(true);
  const partnerName = useDealershipPartnerName();
  const {
    canopy: { carrier = {} },
    loading,
    router
  } = useSelector(state => state);

  const isLoading = loading.includes(PATCH_JOURNEY);
  const scenario = getQueryStringParam(QUERY_PARAMS.SCENARIO);

  const handleClick = () => {
    // Maybe opt-in to signup
    if (checked && scenario === SCENARIOS.IV) {
      dispatch(setScenario(SCENARIOS.SIGN_UP));
    }
    // Maybe create user, IV case, quoteset:
    dispatch(connectEpilogue({ originalScenario: scenario }));

    // Route to next page
    onContinue();
  };

  return (
    <div className={styles.wrapper}>
      <section className={styles.content}>
        <SuccessCheckmark />
        <H3>Connected</H3>
        <p className={styles.text}>{getText({ scenario, carrier: carrier.name, partnerName })}</p>
      </section>

      <section className={styles.footer}>
        {scenario === SCENARIOS.IV && (
          <div className={styles.check}>
            <Checkbox value={checked} save={setChecked} light>
              Include my free insurance report
            </Checkbox>
          </div>
        )}

        <Button
          useKeyboard
          onClick={handleClick}
          className={styles.button}
          showArrow
          blockButtonMobile
          loading={isLoading}
        >
          {getButtonText({ scenario })}
        </Button>

        {showConnectAnother(scenario) && (
          <LinkText
            onClick={() => {
              dispatch(clearCanopy());
              history.replace(connectRoute(router.location.pathname, CONNECT_ROUTE_PAGES.CARRIERS));
            }}
          >
            Connect another policy
          </LinkText>
        )}
      </section>
    </div>
  );
};

SuccessScreen.propTypes = {
  onContinue: PropTypes.func
};

export default SuccessScreen;
