import React, { useState } from 'react';
import { HiOutlineCheckBadge } from 'react-icons/hi2';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PROMPT_NAMES } from 'woop-shared/prompts/names';
import { identify } from '../../../../analytics/klaviyo/api';
import { KLAVIYO_FIELDS } from '../../../../analytics/klaviyo/constants';
import { ROUTES } from '../../../../router/constants/routes';
import { clearCanopy } from '../../../core/actions/canopy';
import WoopLogo from '../../../core/assets/woop_logo.svg';
import AppWrapper from '../../../core/components/AppWrapper';
import Button from '../../../core/components/Button';
import CanopyModal from '../../../core/components/CanopyModal';
import LinkText from '../../../core/components/LinkText';
import PrivacyPolicyLink from '../../../core/components/PrivacyPolicy/Link';
import TermsOfServiceLink from '../../../core/components/TermsOfService/Link';
import { useBoxUpload } from '../../../core/hooks/use-box-upload';
import { useDealershipPartnerName } from '../../../core/hooks/use-dealership-partner-name';
import { useOverlayModal } from '../../../core/hooks/use-overlay-modal';
import { useSlimApp } from '../../../core/hooks/use-slim-app';
import { getVal } from '../../../core/utils/journey-data';
import { patchJourney, PATCH_JOURNEY } from '../../actions/journey';
import { promptsAreValid } from '../../utils/journey-content';
import BoxModal from '../ConnectPage/components/BoxModal';
import PersonalInfoPage from '../Prejourney/components/PersonalInfoPage';
import styles from './styles.css';

const IVConnect = () => {
  useSlimApp({ removeOnUnmount: true });
  const [smsConsent, setSmsConsent] = useState();

  const history = useHistory();
  const dispatch = useDispatch();
  const { journeyData, loading: _loading } = useSelector(state => state);

  const partner = useDealershipPartnerName({ capitalize: true });

  const trackKlaviyo = () =>
    identify({
      email: getVal(journeyData[PROMPT_NAMES.EMAIL]),
      phone: getVal(journeyData[PROMPT_NAMES.PHONE]),
      [KLAVIYO_FIELDS.TEXT_IV_RESULTS]: smsConsent
    });

  const {
    openBoxModal,
    setOpenBoxModal,
    hasFiles,
    handleOpenBox: _handleOpenBox,
    handleUpload,
    onUploadComplete
  } = useBoxUpload({
    onUploadCompleteCallback: () => {
      setOpenBoxModal(false);
      trackKlaviyo();
      history.push(ROUTES.IV_END);
    }
  });

  const [openCanopy, setOpenCanopy] = useOverlayModal();
  const [showValidation, setShowValidation] = useState();

  const loading = _loading.includes(PATCH_JOURNEY);
  const isValid = promptsAreValid(journeyData);

  const handleConnect = () => {
    if (!isValid) return setShowValidation(true);

    // Track sms consent to Klaviyo
    trackKlaviyo();

    // Save journey, start canopy flow.
    dispatch(
      patchJourney({
        callback: () => {
          setOpenCanopy(true);
          history.push(ROUTES.IV_CONNECT_CARRIERS);
        }
      })
    );
  };

  const handleOpenBox = () => {
    if (!isValid) return setShowValidation(true);
    _handleOpenBox();
  };

  const onContinue = () => {
    history.push(ROUTES.IV_END);
  };

  return (
    <AppWrapper className={styles.wrapper} isFloatingCardParent>
      <CanopyModal
        baseRoute={ROUTES.IV_CONNECT}
        open={openCanopy}
        toggleOpen={bool => {
          // If closing, clear canopy state.
          if (!bool) {
            dispatch(clearCanopy());
            history.replace(ROUTES.IV_CONNECT_CARRIERS);
          }
          setOpenCanopy(bool);
        }}
        abortToBox={() => {
          setOpenCanopy(false);
          handleOpenBox();
        }}
        onContinue={onContinue}
      />

      <BoxModal
        open={openBoxModal}
        toggleOpen={setOpenBoxModal}
        onUpload={handleUpload}
        onContinue={onUploadComplete}
        isValid={hasFiles}
      />

      <section className={styles.content}>
        <WoopLogo className="only-mobile" />

        <h3>{partner} is partnered with Woop to verify your insurance.</h3>

        <div className={styles.form}>
          <div className={styles.heading}>Confirm your details</div>
          <PersonalInfoPage
            journeyData={journeyData}
            showValidation={showValidation}
            onCheck={setSmsConsent}
          />
        </div>

        <div className={styles.info}>
          <h4>Next step</h4>
          <p>Sign in with your insurer to instantly and securely connect your policy details.</p>
        </div>

        <div className={styles.buttons}>
          <Button
            onClick={handleConnect}
            loading={loading}
            blockButtonMobile
            className={styles.button}
            classNameInner={styles.inner}
          >
            Verify my insurance
            <HiOutlineCheckBadge />
          </Button>

          <LinkText onClick={handleOpenBox} disabled={loading}>
            Can&apos;t sign in?
          </LinkText>
        </div>

        <div className={styles.legal}>
          <TermsOfServiceLink />
          <PrivacyPolicyLink />
        </div>
      </section>
    </AppWrapper>
  );
};

export default IVConnect;
